<template>
<div v-if="showExportModal">
    <div class="sam-modal modal in show modal-active modal-open" :class="{'closing': modalIsClosing}">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <SamH2
                :title="title"
                noSpace
            />
            <button
              type="button" 
              class="sam-modal-close close"
              v-on:click="closeModal()"
            >
                <img src="../assets/images/close.svg"/>
            </button>
          </div>
          <div class="modal-body">
            <slot/>
            <div class="row sam-modal-footer">
                <div class="col-sm-12 col-md-4 mb-4 mb-md-0">
                    <SamPrimaryButton :text="saveButtonText" @buttonAction="saveModal()"/>
                </div>
                <div class="col-sm-12 col-md-4 m-0">
                    <SamSecondaryButton text="Close" @buttonAction="closeModal()"/>
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>
     <div v-if="showExportModal" class="modal-backdrop show" :class="{'closing': modalIsClosing}"></div>
</div>
</template>

<script>
import SamH2 from './SamH2.vue';
import SamPrimaryButton from './SamPrimaryButton.vue';
import SamSecondaryButton from './SamSecondaryButton.vue';

export default {
  name: "SamModal",
  components: { 
    SamH2,
    SamPrimaryButton,
    SamSecondaryButton
  },
 props: {
    title: {
        type: String,
        default: ''
    },
    modalId: {
      type: String,
      default: ''
    },
    showExportModal: {
      type: Boolean,
      default: false
    },
    modalIsClosing: {
      type: Boolean,
      default: false
    },
    saveButtonText: {
      type: String,
      default: 'Save'
    }
  },
  data(){
    return {
    }
  },
  methods: {
    closeModal(){
      this.$emit('close');
    },
    saveModal(){
      this.$emit('save');
    }
  }
}
</script>
