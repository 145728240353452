<template>
  <div>
    <!-- Modal  -->
    <div v-if="showModal" class="modal fade in show modal-active modal-open" id="editStockModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">Edit stock quantity for {{ editVariant.name }}</h4>
            <button type="button" class="close" v-on:click="hideModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                Add to stock (or remove from stock with minus)
              </div>
              <div class="col-12">
                <input class="inputfield" v-model.number="stockEditValue" type="number"/>
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
            <div class="col-md-4">
              <button type="button" class="btn btn-lg btn-outline-primary btn-block" v-on:click="hideModal()">Cancel
              </button>
            </div>
            <div class="col-md-4">
              <button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="editStock()">Save changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showModal" class="modal-backdrop show"></div>
    <ArchiveProductModal v-if="showArchiveProductModal" @closeArchiveProductModal="closeArchiveProductModal"
                         @archiveProduct="updateProduct(true)"/>
    <NotifyUsersModal v-if="showNotifyUsersModal" v-bind:product="product" @userNotification="userNotification"/>
    <StockLevelNotificationModal v-if="showStockLevelModal" v-bind:product="product"
                                 @sendUpdate="sendStockLevelUpdate()"
                                 @closeStockLevelModal="showStockLevelModal = false;"/>
    <ProductTranslationModal v-if="showProductTranslationModal"
                             @closeProductTranslationModal="showProductTranslationModal = false"
                             :defaultProduct="product"
    />
    <!-- HS Codes Modal section -->
    <div v-if="showHSCodesModal" class="modal fade in show modal-active modal-open" id="hsCodesModal">
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="modal-header">
            <h4 class="modal-title">HS Codes</h4>
            <button type="button" class="close" v-on:click="hideHSCodesModal()">&times;</button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <ul class="custom-list">
                  <li v-for="(category, key) in hsCodesData" :key="key" @click="openHSCategory(key)">
                    > {{ category.name }}
                    <ul class="custom-list" v-show="category.show">
                      <li v-for="(subCategory,subKey) in hsCodesData[key].categories" :key="subKey" @click="openHSSubCategory(subCategory.category, false, $event)">
                        > {{subCategory.category}} : {{subCategory.label}}
                        <ul class="custom-list" v-show="subCategory.show">
                          <li v-for="(innerSubCategory, innerSubKey) in subCategory.categories" :key="innerSubKey" @click="openHSSubCategory(innerSubCategory.category, false, $event)">
                            > {{innerSubCategory.category}} : {{innerSubCategory.label}}
                            <ul class="custom-list" v-show="innerSubCategory.show">
                              <li v-for="(subInnerSubCategory, subInnerSubKey) in innerSubCategory.categories" :key="subInnerSubKey" @click="openHSSubCategory(subInnerSubCategory.category, false, $event)">
                              > {{subInnerSubCategory.category}} : {{subInnerSubCategory.label}}
                                <ul class="custom-list" v-show="subInnerSubCategory.show">
                                  <li v-for="(innerSubInnerSubCategory, innerSubInnerSubKey) in subInnerSubCategory.categories" :key="innerSubInnerSubKey" @click="openHSSubCategory(innerSubInnerSubCategory.category, true, $event)">
                                    > {{innerSubInnerSubCategory.category}} : {{innerSubInnerSubCategory.label}}
                                  </li>
                                </ul>
                            </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  <hr v-if="parseInt(key) !== Object.keys(hsCodesData).length">
                  </li>
                </ul>
              </div>
            </div>
          </div><!-- Modal body end -->
          <!-- Modal footer -->
          <div class="modal-footer">
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-outline-primary btn-block" v-on:click="hideHSCodesModal()">Cancel</button></div>
              <div class="col-md-4"><button type="button" class="btn btn-lg btn-primary btn-block" v-on:click="clearHSCode()">Clear Code</button></div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="showHSCodesModal" class="modal-backdrop show"></div>
    <div v-if="updating">
      <div class="loader-container">
        <div class="row justify-content-center">
          <div class="col-md-4 d-flex justify-content-center">
            <div class="d-flex justify-content-center" style="margin-top: 45% !important">
              <div class="loader" style="margin-top: 0 !important">Loading...</div>
              <div class="text-center" style="margin-top: 70px;" v-if="$store.getters['admin/uploadingPrintFile']">
                <div class="font-weight-bold">Uploading print file</div>
                <div style="max-width: 250px; overflow: hidden; text-overflow: ellipsis;"
                     v-if="$store.getters['admin/printFileName']">{{ $store.getters['admin/printFileName'] }}
                </div>
                <div class="font-weight-bold">{{ $store.getters['admin/printFileProgress'] }}% uploaded</div>
                <button class="btn btn-lg btn-outline-primary btn-block mt-2" style="width: 250px;"
                        v-on:click="cancelPrintFileUpload()">Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="row mt-4">
        <div class="col-md-3">
          <div>Product image</div>
          <img v-if="productImage" class="img-width" :src="productImage"/>
          <div>
            <label for="product_image" class="custom-file-upload mt-3">
              <i class="fa fa-cloud-upload"></i> Choose file
            </label>
            <input class="inputfile" type="file" id="product_image" ref="product_image"
                   v-on:change="handleFileUpload(true)"/>
          </div>
          <div class="mt-4">Other product images (max 3)</div>
          <div class="row">
            <draggable v-model="product_images" group="gallery" @start="drag=true" @end="drag=false"
                       direction="horizontal" class="d-flex">
              <div v-for="(i, index) in product_images" :key="i.thumb_big" class="col-4 mt-3">
                <div class="pointer prod-thumb-x-pos" v-on:click="removeGalleryItem(index)">
                  <span class="remove-x float-right mt-1">X</span>
                </div>
                <img class="img-width cursor-move mb-2" :src="galleryThumb(index)"/>
              </div>
            </draggable>
          </div>
          <div>
            <label for="product_images" class="custom-file-upload mt-0" :class="{ 'opacity-03': allSelected}">
              <i class="fa fa-cloud-upload"></i> Choose file
            </label>
            <input class="inputfile" type="file" id="product_images" ref="product_images"
                   v-on:change="handleFileUpload(false)" :disabled="allSelected"/>
          </div>
          <div class="mt-4">Print/product file</div>
          <div v-show="!printFileName">
            <label for="printFile" class="custom-file-upload mt-0">
              <i class="fa fa-cloud-upload"></i> Choose file (max 500MB)
            </label>
            <input class="inputfile" type="file" id="printFile" ref="printFile" v-on:change="handlePrintFile()"/>
          </div>
          <div class="row" v-if="printFileName">
            <div class="col-10">
              <a v-if="product.print_file" class="float-left mr-1" :href="product.print_file">{{ printFileName }}</a>
            <span v-else class="float-left mr-1">{{ printFileName }}</span>
            </div>
            <div class="col-2 pointer d-flex justify-content-center align-items-center">
              <span class="remove-x" v-on:click="removePrintFile()">X</span>
            </div>
          </div>
        </div>
        <div class="col-md-3">
          <div>Product name*</div>
          <input type="text" class="inputfield" v-model="product.name">
          <div v-if="$v.product.name.$error">
            <div class="form-field-error" v-if="!$v.product.name.required">Product name is required</div>
          </div>
          <div class="mt-4">Price ({{ $store.getters.currencyStr }})*</div>
          <input type="number" step="any" class="inputfield" min="0" v-model="product.price">
          <div v-if="$v.product.price.$error">
            <div class="form-field-error" v-if="!$v.product.price.required">Product price is required</div>
            <div class="form-field-error" v-else-if="!$v.product.price.minValue">Minimum price is 0</div>
          </div>
          <div class="mt-4">Product description*</div>
          <div><textarea rows=5 v-model="product.description"/></div>
          <div v-if="$v.product.description.$error">
            <div class="form-field-error" v-if="!$v.product.description.required">Product description is required</div>
          </div>
           <div>
            <div class="row">
              <div class="col-6 pr-1">
                <div class="mt-2">SKU</div>
                <input class="inputfield" v-model="product.ext_product_id" type="text">
              </div>
              <div class="col-6 pl-0">
                <div class="mt-2">Barcode</div>
                <input  type="text" class="inputfield" v-model="product.barcode">
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-3 mt-4 mt-md-0">
          <div>Product category*</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.category"
              :options="categoryArray"
              :reduce="c => c.value"
              :clearable="false"
              placeholder="Select category"
          ></v-select>
          <div v-if="$v.product.category.$error">
            <div class="form-field-error" v-if="!$v.product.category.required">Product category is required</div>
          </div>
          <div class="mt-4">Product tags</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.tags"
              :options="tags"
              :reduce="t => t.id"
              multiple
              placeholder="Select tags"
              @input="tagsChanged = true"
          ></v-select>
          <div class="mt-4">Lead time*</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.delivery_time"
              :options="deliveryTimeArray"
              :reduce="d => d.value"
              :clearable="false"
              placeholder="Select delivery time"
          ></v-select>
          <div v-if="$v.product.delivery_time.$error">
            <div class="form-field-error" v-if="!$v.product.delivery_time.required">Product delivery time is required
            </div>
          </div>
          <div class="mt-4">Minimum order quantity*</div>
          <input class="inputfield" v-model.number="product.moq" type="number" step="1">
          <div v-if="$v.product.moq.$error">
            <div class="form-field-error" v-if="!$v.product.moq.required">Product MOQ is required</div>
            <div class="form-field-error" v-else-if="!$v.product.moq.minValue">Minimum MOQ is 1</div>
          </div>
          <div class="mt-4">Order unit</div>
          <input class="inputfield" v-model.number="product.order_unit" type="number" step="1">
        </div>
        <div class="col-md-3">
          <div>Status*</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.status"
              :options="statusArray"
              :reduce="s => s.value"
              :clearable="false"
              placeholder="Select status"
          ></v-select>
          <div v-if="$v.product.status.$error">
            <div class="form-field-error" v-if="!$v.product.status.required">Product status is required</div>
          </div>
          <div v-if="$store.getters.isAdmin">
            <div class="mt-4">Producer (supplier)*</div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.producer"
                :options="storeProducers"
                :reduce="s => s.id"
                :clearable="false"
                placeholder="Select producer"
            ></v-select>
            <div v-if="$v.product.producer.$error">
              <div class="form-field-error" v-if="!$v.product.producer.required">Producer is required</div>
            </div>
          </div>
          <!--          <div v-if="product.order_recipient == 2 || !$store.getters.isStaff">-->
          <!--            <div class="mt-4">Supplier email*</div>-->
          <!--            <input v-if="!$store.getters.isStaff && product.order_recipient == 1" type="text" class="inputfield" :value="'production@framme.com'" disabled>-->
          <!--            <input v-else type="text" class="inputfield" v-model="product.recipient_email">-->
          <!--            <div v-if="$v.product.recipient_email.$error">-->
          <!--              <div class="form-field-error" v-if="!$v.product.recipient_email.orRequired">Supplier email is required</div>-->
          <!--              <div class="form-field-error" v-else-if="!$v.product.recipient_email.validChars">å, ä, ö are not allowed characters</div>-->
          <!--            </div>-->
          <!--          </div>-->
          <div class="mt-4">Product visibility</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.groups"
              :options="$store.getters.groups"
              :reduce="s => s.id"
              :clearable="true"
              multiple
              placeholder="All user groups"
          ></v-select>
          <div class="mt-4">Translations</div>
          <button type="button"
                  class="btn btn-lg btn-outline-primary w-100 mt-0"
                  @click="showProductTranslationModal = true"
                  :disabled="!productCreated"
          >Add and edit
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <hr/>
        </div>
      </div>
      <div v-if="$store.getters.isStaff && productProducerFramme">
        <div class="row">
          <div class="col-12">
            <h3>Framme specific</h3>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-3">
            <div>Producer</div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.supplier"
                :options="$store.getters['admin/staffProductOptions'].producers"
                :reduce="p => p.value"
                placeholder="Producer"
            ></v-select>
          </div>
          <div class="col-12 col-md-3">
            <div>Purchase cost (EUR)</div>
            <input type="number" step="any" class="inputfield" min="0" v-model="product.purchase_cost">
          </div>
          <div class="col-12 col-md-3">
            <div>Send orders to Salesforce</div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.include_in_mia"
                :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                :reduce="p => p.value"
                :clearable="false"
                placeholder="Select stock management"
            ></v-select>
          </div>
          <div class="col-12 col-md-3">
            <div>Send orders also to supplier <img id="email-supplier-tooltip" src="@/assets/images/tooltip.svg"
                                                   title="Tooltip"/>
              <b-tooltip target="email-supplier-tooltip" triggers="hover">
                You can automate orders by sending order confirmation emails directly to
                the supplier{{ supplierName ? " " + supplierName : "" }}'s
                email{{ supplierEmail ? " " + supplierEmail : "" }}.
                To do this you need to select a producer and add a primary email to the producer in MIA.
              </b-tooltip>
            </div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.automate_order"
                :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
                :reduce="p => p.value"
                :clearable="false"
                placeholder="Select"
                :disabled="!product.supplier || product.stock_management"
            ></v-select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12 col-md-3">
            <div>Sustainable materials</div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.material"
                :options="materialOptions"
                :reduce="s => s.value"
                :clearable="true"
                multiple
                placeholder="Sustainable materials"
            ></v-select>
          </div>
          <div class="col-12 col-md-3">
            <div>Sustainable disposal</div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.disposal"
                :options="disposalOptions"
                :reduce="s => s.value"
                :clearable="true"
                multiple
                placeholder="Sustainable disposal"
            ></v-select>
          </div>
          <div class="col-12 col-md-3">
            <div>Circular economy</div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.circular_economy"
                :options="circularOptions"
                :reduce="s => s.value"
                :clearable="true"
                multiple
                placeholder="Circular economy"
            ></v-select>
          </div>
          <div class="col-12 col-md-3">
            <div>Green logistics</div>
            <v-select
                class="sam-select"
                label="name"
                v-model="product.green_logistics"
                :options="logisticOptions"
                :reduce="s => s.value"
                :clearable="true"
                placeholder="Green logistics"
            ></v-select>
          </div>
        </div>
        <div class="row mt-4">
          <div class="col-12">
            <hr/>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Variants</h3>
        </div>
      </div>
      <div class="row mb-3" v-for="(variant, index) in product.variants" v-bind:key="'var-' + index">
        <div class="col-md-3">
          <div>Name (e.g. size or color)*</div>
          <div v-if="product.variants.length === 1 && stockManagement"><input class="inputfield gray"
                                                                              v-model="product.variants[index].name"
                                                                              type="text" disabled/></div>
          <div v-else><input @input="variantNameChanged = true;" class="inputfield"
                             v-model="product.variants[index].name" type="text"/></div>
          <div v-if="$v.product.variants.$each[index].name.$error">
            <div class="form-field-error">Variant name is required</div>
          </div>
        </div>
        <div class="col-md-3">
          <div class="row">
              <div class="col-6 pr-1">
                <div class="mt-2">SKU</div>
                <input class="inputfield" v-model="product.variants[index].sku" type="text">
              </div>
              <div class="col-6 pl-0">
                <div class="mt-2">Barcode</div>
                <input  type="text" class="inputfield" v-model="product.variants[index].barcode">
              </div>
            </div>
        </div>
        <div class="col-md-3" v-if="product.stock_management">
          <div>Stock quantity*</div>
          <div class="row" v-if="product.variants[index].id && productData.stock_management">
            <div class="col-6 pr-1">
              <input class="inputfield" v-model.number="product.variants[index].stock_quantity" type="number" min="0"
                     disabled/>
            </div>
            <div class="col-6 pl-0">
              <button class="btn btn-lg btn-primary btn-block mt-0" v-on:click="editStockModal(index)">Edit</button>
            </div>
          </div>
          <div class="row" v-else>
            <div class="col-12">
              <input class="inputfield" v-model.number="product.variants[index].stock_quantity" type="number" min="0"/>
            </div>
          </div>
          <div v-if="$v.product.variants.$each[index].stock_quantity.$error">
            <div class="form-field-error" v-if="!$v.product.variants.$each[index].stock_quantity.sqRequired">Stock
              quantity is required
            </div>
          </div>
        </div>
        <div class="col-md-2" v-if="product.stock_management">
          <div>Low stock threshold*</div>
          <div><input class="inputfield" v-model.number="product.variants[index].low_stock_threshold" type="number"
                      min="0"/></div>
          <div v-if="$v.product.variants.$each[index].low_stock_threshold.$error">
            <div class="form-field-error" v-if="!$v.product.variants.$each[index].low_stock_threshold.lstRequired">Low
              stock threshold is required
            </div>
          </div>
        </div>
        <div v-else-if="variant.id" class="col-md-3">
          <div class="row h-100">
            <div class="col-12">Variant specific print file</div>
            <div class="col-12" v-show="!variant.print_file">
              <label :for="'printFileVar_' + variant.id" class="custom-file-upload mt-0">
                <i class="fa fa-cloud-upload"></i> Choose file (max 500MB)
              </label>
              <input class="inputfile" type="file" :id="'printFileVar_' + variant.id"
                     :ref="'printFileVar_' + variant.id" v-on:change="saveVariantPrintFile(variant.id)"/>
            </div>
            <div v-if="variant.print_file" class="col-12 text-break align-middle">
              <a class="float-left mr-1" target="_blank"
                 :href="variant.print_file">{{ getPrintFileName(variant.print_file) }}</a>
              <div class="pointer">
                <span class="remove-x align-top" v-on:click="removeVariantPrintFile(variant.id)">X</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-md-1 d-flex justify-content-end align-items-end">
          <div class="d-none d-md-block round-bg pointer mb-2" v-on:click="removeVariant(index)">x</div>
          <button class="d-block d-md-none btn btn-lg btn-outline-primary btn-block mt-2"
                  v-on:click="removeVariant(index)">Delete variant
          </button>
        </div>
        <div class="col-12 d-block d-md-none">
          <hr/>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-3">
          <div class="add-new-item" v-on:click="addVariant()">
            <div class="round-bg">+</div>
            Add new variant
          </div>
        </div>
        <div class="col-md-3">
          <button v-if="stockManagement && !variantsChanged" class="btn btn-lg btn-outline-primary btn-block mt-2"
                  v-on:click="showStockLevelModal = true;">Send current stock update
          </button>
          <button v-if="stockManagement && variantsChanged" disabled class="btn btn-lg btn-primary btn-block mt-2"
                  v-on:click="showStockLevelModal = true;">Save changes first...
          </button>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-12">
          <hr/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Stock management</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div>Manage stock</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.stock_management"
              :options="[{name: 'Yes', value: true}, {name: 'No', value: false}]"
              :reduce="s => s.value"
              :clearable="false"
              placeholder="Select stock management"
          ></v-select>
        </div>
        <div class="col-md-3" v-if="product.stock_management">
          <div>Warehouse (supplier)*</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.warehouse"
              :options="storeWarehouses"
              :reduce="s => s.id"
              :clearable="false"
              placeholder="Select warehouse"
          ></v-select>
          <div v-if="$v.product.warehouse.$error">
            <div class="form-field-error" v-if="!$v.product.warehouse.required">Warehouse is required</div>
          </div>
        </div>
        <div class="col-md-3" v-if="product.stock_management">
          <div>Low stock notification recipient*</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.low_stock_recipient"
              :options="profiles"
              :reduce="s => s.value"
              :clearable="false"
              placeholder="Select recipient"
          ></v-select>
          <div v-if="$v.product.low_stock_recipient.$error">
            <div class="form-field-error" v-if="!$v.product.low_stock_recipient.required">Low stock recipient is
              required
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4">
          <hr/>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h3>Shipping and export</h3>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <div>
            <div class="row">
              <div class="col-6 pr-1">
                <div>Weight (kg)</div>
                <input type="text" class="inputfield" v-model="product.weight">
              </div>
              <div class="col-6 pl-0">
                <div>Width (cm)</div>
                <input type="text" class="inputfield" v-model="product.width">
                <div v-if="$v.product.width.$error">
                  <div class="form-field-error" v-if="!$v.product.width.minValue">Min. 1 cm</div>
                  <div class="form-field-error" v-if="!$v.product.width.required">Width is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">Type of export</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.type_of_export"
              :options="exportTypes"
              :reduce="s => s.value"
              placeholder="Select export type"
          ></v-select>
        </div>
        <div class="col-md-3">
          <div>
            <div class="row">
              <div class="col-6 pr-1">
                <div>Height (cm)</div>
                <input type="text" class="inputfield" v-model="product.height">
                <div v-if="$v.product.height.$error">
                  <div class="form-field-error" v-if="!$v.product.height.minValue">Min. 1 cm</div>
                  <div class="form-field-error" v-if="!$v.product.height.required">Height is required</div>
                </div>
              </div>
              <div class="col-6 pl-0">
                <div>Length (cm)</div>
                <input type="text" class="inputfield" v-model="product.length">
                <div v-if="$v.product.length.$error">
                  <div class="form-field-error" v-if="!$v.product.length.minValue">Min. 1 cm</div>
                  <div class="form-field-error" v-if="!$v.product.length.required">Length is required</div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">Country of origin</div>
          <v-select
              class="sam-select"
              label="name"
              v-model="product.origin_country.code"
              :options="countries"
              :reduce="s => s.value"
              placeholder="Select country"
          ></v-select>
        </div>
        <div class="col-md-3">
          <div>
            <div>HS / Tariff code</div>
            <input type="text" class="inputfield" v-model.trim="product.hs_code" @click="openHSCodesModal"></div>
        </div>
        <div class="col-md-3">
          <div>
            <div>Product decription for customs</div>
            <div><textarea rows=5 v-model="product.customer_prod_description"/></div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-4">
          <hr/>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-md-3">
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="updateProduct()" v-if="editProduct">Save
            product
          </button>
          <button class="btn btn-lg btn-primary btn-block mt-2" v-on:click="createProduct()" v-else>Create product
          </button>
        </div>
        <div class="col-md-3">
          <router-link :to="{ name: 'storemanagement', params: { tab: 'products' }, hash: urlHash}">
            <button class="btn btn-lg btn-outline-primary btn-block mt-2">Back to product listing</button>
          </router-link>
        </div>
      </div>
      <div class="mb-5" v-if="product.stock_management || (product.stock_updates && product.stock_updates.length > 0)">
        <div class="row">
          <div class="col-12 mb-2 mb-md-0">
            <h3>Stock history updates</h3>
          </div>
        </div>
        <div class="row d-none d-md-flex font-grey">
          <div class="col-md-3">
            Date
          </div>
          <div class="col-md-3">
            User
          </div>
          <div class="col-md-3">
            Variant
          </div>
          <div class="col-md-3">
            Change to quantity (new)
          </div>
        </div>
        <div class="row" v-for="update in product.stock_updates" v-bind:key="update.id">
          <div class="col-6 col-md-3 mb-3 mb-md-0">
            <div class="d-block d-md-none font-grey">Date</div>
            {{ parseDate(update.created) }}
          </div>
          <div class="col-6 col-md-3">
            <div class="d-block d-md-none font-grey">User</div>
            {{ update.user_name }}
          </div>
          <div class="col-6 col-md-3">
            <div class="d-block d-md-none font-grey">Variant</div>
            {{ update.variant_name }}
          </div>
          <div class="col-6 col-md-3">
            <div class="d-block d-md-none font-grey">Change to quantity</div>
            <div v-if="update.deleted || update.added">
              {{ update.deleted ? 'Deleted' : 'Added' }} ({{ update.quantity_after_update }})
            </div>
            <div v-else>
              <span v-if="update.update_quantity > 0">+</span>{{ update.update_quantity }}
              ({{ update.quantity_after_update }})
            </div>
          </div>
          <div class="col d-md-none">
            <hr/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ArchiveProductModal from './ArchiveProductModal.vue'
import NotifyUsersModal from './NotifyUsersModal.vue'
import StockLevelNotificationModal from './StockLevelNotificationModal.vue'
import {required, minValue, requiredIf} from 'vuelidate/lib/validators'
import draggable from 'vuedraggable'
import ProductTranslationModal from '@/modules/storemanagement/components/ProductTranslationModal.vue'

export default {
  name: 'CreateEditProduct',
  components: {
    draggable,
    ArchiveProductModal,
    NotifyUsersModal,
    StockLevelNotificationModal,
    ProductTranslationModal,
  },
  props: [
    "editProduct",
    "productData"
  ],
  data() {
    return {
      newProduct: {
        name: null,
        price: null,
        description: null,
        category: null,
        delivery_time: null,
        moq: null,
        order_unit: null,
        ext_product_id: null,
        barcode: null,
        stock_management: false,
        status: 1,
        order_recipient: 2,
        origin_country: {code: null, name: null},
        variants: [],
        tags: [],
        groups: [],
        automate_order: false,
        weight: null,
        width: null,
        height: null,
        length: null,
        type_of_export: 1,
        customer_prod_description: null,
        producer: null,
        warehouse: null
      },
      product: {origin_country: {code: null, name: null}},
      product_image: null,
      product_images: [],
      showPreview: false,
      imagePreview: null,
      acceptedMimeTypes: [
        'image/gif',
        'image/jpeg',
        'image/png',
        'image/webp'
      ],
      updating: false,
      showModal: false,
      editVariant: null,
      stockEditValue: 0,
      showArchiveProductModal: false,
      showNotifyUsersModal: false,
      showStockLevelModal: false,
      showProductTranslationModal: false,
      tagsChanged: false,
      variantNameChanged: false,
      printFileObj: null,
      printFileChanged: false,
      maxPrintFileSize: 500000000, // 500MB
      uploadingVariantId: null,
      producerChanged: false,
      warehouseChanged: false,
      showHSCodesModal: false,
      hsCodes: {},
      selectedHSCode: [],
    }
  },
  validations: {
    product: {
      name: {
        required
      },
      price: {
        required,
        minValue: minValue(0)
      },
      description: {
        required
      },
      category: {
        required
      },
      delivery_time: {
        required
      },
      moq: {
        required,
        minValue: minValue(1)
      },
      status: {
        required
      },
      height: {
        minValue: minValue(1),
        required: requiredIf(function () {
          return this.product.height !== null && this.product.height !== '';
        })
      },
      length: {
        minValue: minValue(1),
        required: requiredIf(function () {
          return this.product.length !== null && this.product.length !== '';
        })
      },
      width: {
        minValue: minValue(1),
        required: requiredIf(function () {
          return this.product.width !== null && this.product.width !== '';
        })
      },
      variants: {
        $each: {
          name: {
            required
          },
          stock_quantity: {
            sqRequired(stock_quantity) {
              if (this.product.stock_management && (stock_quantity == null || stock_quantity < 0 || typeof stock_quantity !== "number")) {
                return false;
              }
              return true;
            }
          },
          low_stock_threshold: {
            lstRequired(low_stock_threshold) {
              if (this.product.stock_management && (low_stock_threshold == null || low_stock_threshold < 0 || typeof low_stock_threshold !== "number")) {
                return false;
              }
              return true;
            }
          }
        }
      },
      low_stock_recipient: {
        required: requiredIf(function (product) {
          return product.stock_management
        })
      },
      producer: {
        required
      },
      warehouse: {
        required: requiredIf(function (product) {
          return product.stock_management
        })
      }
    }
  },
  mounted() {
    if (this.editProduct) {
      if (this.productData && this.productData.id) {
        // make a copy of productData
        if (!this.productData.origin_country || this.productData.origin_country == "") {
          this.product = {...this.productData, ...{origin_country: {code: null, name: null}}};
        } else {
          this.product = {...this.productData}
        }
        // copy gallery images
        this.product_images = [...this.productData.images];
      } else {
        // No product data available, fetch it
        this.getProduct();
      }
    } else {
      this.product = {...this.newProduct}
    }
  },
  computed: {
    options() {
      return this.$store.getters['admin/productOptions'];
    },
    exportTypes() {
      const exportTypes = this.options.export_types;
      return exportTypes.map(c => ({name: c[1], value: c[0]}));
    },
    countries() {
      if (!this.$store.getters['admin/countriesFetched']) {
        this.$store.dispatch('admin/fetchCountries')
      }
      const countries = this.$store.getters['admin/countries'].map(c => ({name: c[1], value: c[0]}))
      // remove duplicates
      return countries.filter((v, i, a) => a.findIndex(t => (t.value === v.value)) === i);
    },
    stockManagement() {
      return this.product.stock_management;
    },
    productImage() {
      if (!this.showPreview && this.product.primary_image) {
        if (this.product.primary_image.startsWith(process.env.VUE_APP_IMAGE_HOST)) {
          return this.product.primary_image;
        } else {
          return process.env.VUE_APP_IMAGE_HOST + this.product.primary_image;
        }
      } else if (this.showPreview && this.imagePreview) {
        return this.imagePreview;
      }
      return null;
    },
    productImages() {
      const imgs = [];
      for (const img of this.product_images) {
        imgs.push(img.imagePreview);
      }
      return imgs;
    },
    printFileName() {
      if (this.product.print_file) {
        return this.product.print_file.split('/').slice(-1)[0];
      } else if (this.printFileObj) {
        return this.printFileObj.name;
      }
      return null;
    },
    profiles() {
      if (!this.$store.getters['admin/profilesFetched']) {
        this.$store.dispatch('admin/fetchMembers');
        return [];
      }
      return this.$store.getters['admin/storeProfiles'].map(p => ({
        name: p.user.first_name + " " + p.user.last_name,
        value: p.user.id
      }));
    },
    allSelected() {
      return this.product_images.length >= 3
    },
    supplierOptions() {
      if (!this.options.supplier) {
        return [];
      }
      if (this.$store.getters.storeFeatures && this.$store.getters.storeFeatures.own_products_allowed) {
        return this.options.supplier.map(s => ({name: s[1], value: s[0]}));
      }
      return this.options && this.options.supplier ? this.options.supplier.filter(s => s[0] == 1).map(s => ({
        name: s[1],
        value: s[0]
      })) : [];
    },
    storeSuppliers() {
      if (!this.$store.getters['admin/suppliersFetched']) {
        this.$store.dispatch('admin/fetchSuppliers')
      }
      return this.$store.getters['admin/suppliers']
    },
    storeProducers() {
      // 1 - Producer (all store products (excl. lease products))
      return this.storeSuppliers.filter(s => s.type === 1)
    },
    storeWarehouses() {
      // 2 - Warehouse (all store products that has "Manage Stock" on and lease products)
      return this.storeSuppliers.filter(s => s.type === 2)
    },
    productProducerId() {
      return this.product.producer
    },
    productWarehouseId() {
      return this.product.warehouse
    },
    productProducer() {
      return this.storeProducers.find(sp => sp.id === this.productProducerId)
    },
    productProducerFramme() {
      if (this.productProducer) {
        return this.productProducer.is_framme
      }
      return false
    },
    categoryArray(){
      if(!this.options.categories){
        return [];
      }
      return this.options.categories.map(c => ({name: c[1], value: c[0]}))
    },
    hsCodesCategories(){
      if(!this.options.hs_codes_categories){
        return [];
      }
      return this.options.hs_codes_categories
    },
    hsCodesData() {
      return this.hsCodes;
    },
    deliveryTimeArray() {
      if (!this.options.delivery_times) {
        return [];
      }
      return this.options.delivery_times.map(d => ({name: d[1], value: d[0]}))
    },
    statusArray() {
      if (!this.options.statuses) {
        return [];
      }
      return this.options.statuses.map(s => ({name: s[1], value: s[0]}))
    },
    tags() {
      return this.$store.getters.productTags;
    },
    variantsChanged() {
      if (!this.product || !this.product.variants) {
        return false;
      }
      let newVars = this.product.variants.filter(v => !v.id)
      if (newVars.length > 0 || this.variantNameChanged) {
        return true;
      }
      return false;
    },
    urlHash() {
      if (this.$route.params.hash) {
        return '#' + this.$route.params.hash
      }
      return ""
    },
    materialOptions() {
      if (this.$store.getters['admin/staffProductOptsFetched']) {
        return this.$store.getters['admin/staffProductOptions'].product_materials.map(m => ({name: m[1], value: m[0]}));
      } else {
        return []
      }
    },
    disposalOptions() {
      if (this.$store.getters['admin/staffProductOptsFetched']) {
        return this.$store.getters['admin/staffProductOptions'].product_disposals.map(m => ({name: m[1], value: m[0]}));
      } else {
        return []
      }
    },
    circularOptions() {
      if (this.$store.getters['admin/staffProductOptsFetched']) {
        return this.$store.getters['admin/staffProductOptions'].product_circular_economy.map(m => ({
          name: m[1],
          value: m[0]
        }));
      } else {
        return []
      }
    },
    logisticOptions() {
      if (this.$store.getters['admin/staffProductOptsFetched']) {
        return this.$store.getters['admin/staffProductOptions'].product_green_logistics.map(m => ({
          name: m[1],
          value: m[0]
        }));
      } else {
        return []
      }
    },
    supplierName() {
      if (this.product.supplier) {
        let s = this.$store.getters['admin/staffProductOptions'].producers.find(p => p.value == this.product.supplier)
        if (s) {
          return s.name;
        }
      }
      return null;
    },
    supplierEmail() {
      if (this.product.supplier) {
        let s = this.$store.getters['admin/staffProductOptions'].producers.find(p => p.value == this.product.supplier)
        if (s) {
          return s.contact_email;
        }
      }
      return null;
    },
    productCreated() {
      return this.productData && this.productData.id
    },
  },
  watch: {
    stockManagement: function (newVal, oldVal) {
      if (!oldVal && newVal) {
        if (this.product.variants.length === 0) {
          this.addVariant(true);
        }
      } else if (oldVal && !newVal) {
        // remove only variant if stock_management true->false
        if (this.product.variants.length === 1) {
          this.removeVariantIndex = 0
          this.removeVariant();
        }
        // remove the product from the warehousing supplier
        this.product.warehouse = null
      }

      if (newVal) {
        // stock enabled -> set automate_order to false
        this.product.automate_order = false;
      }
    },
    productData: function (newVal) {
      // Watch if productData is updated later after page refresh
      if (this.editProduct && !this.product.id) {
        this.product = {...newVal};
        if (!newVal.origin_country || newVal.origin_country == "") {
          this.product = {...newVal, ...{origin_country: {code: null, name: null}}};
        } else {
          this.product = {...newVal}
        }
        this.product_images = [...newVal.images];
      }
    },
    product_image: function (newVal) {
      if (newVal && newVal.name) {
        if (newVal.name.substring(0, newVal.name.lastIndexOf(".")).length > 80) {
          // Make sure the filename is max 80char + extension
          let newFileName = newVal.name.substring(0, newVal.name.lastIndexOf(".")).substring(0, 80);
          const fileExt = newVal.name.substring(newVal.name.lastIndexOf(".") + 1)
          if (!newVal.name.lastIndexOf(".")) {
            // no dot in filename, just shorten the string
            newFileName = newVal.name.substring(0, 80);
          } else {
            // add file extension to name
            newFileName += "." + fileExt;
          }
          // replace the file with new one to update the name
          this.product_image = new File([this.product_image], newFileName, {
            type: this.product_image.type,
            lastModified: this.product_image.lastModified,
          });
        }
      }
    },
    supplierEmail: function (newVal) {
      if (!newVal) {
        // no supplier selected -> set automate_order to false
        this.product.automate_order = false;
      }
    },
    productProducerId: function(newVal, oldVal) {
      this.producerChanged = newVal !== oldVal
    },
    productWarehouseId: function(newVal, oldVal) {
      this.warehouseChanged = newVal !== oldVal
    },
  },
  methods: {
    getProduct() {
      const vm = this;
      this.updating = true;
      const productId = this.$route.params.itemId;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.state.activeStore.id + '/products/' + productId + "/";
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
          .then(function (response) {
            //vm.product = response.data;
            vm.$store.dispatch('admin/addProduct', response.data);
            vm.updating = false;
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
            vm.updating = false;
          });
    },
    createProduct(notificationConfirmed = false, sendNotification = false) {
      this.updating = true;
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.updating = false;
        return;
      }
      if (this.product.status == 2 && !notificationConfirmed) {
        // Show notification modal when creating a new product
        this.showNotifyUsersModal = true;
        return;
      }
      const vm = this;
      let data = {...this.product};
      data.store = this.$store.getters.activeStore.id;
      data['notify_users'] = sendNotification;
      data['length'] = data['length'] === ''? null : data['length']
      data['width'] = data['width'] === ''? null : data['width']
      data['height'] = data['height'] === ''? null : data['height']
      data['weight'] = data['weight'] === ''? null : data['weight']
      // update picture separately
      delete data.primary_image;
      delete data.primary_thumb_big;
      delete data.primary_thumb_small;
      // update print_file separately
      delete data.print_file;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/products/';
      let method = 'POST';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(function (response) {
            // refresh suppliers in global state
            vm.$store.dispatch('admin/clearAndFetchSuppliers')

            if (vm.tagsChanged) {
              vm.$store.dispatch('updateProductToTags', {productId: data.id, tagIds: data.tags, isLeaseProduct: false})
              vm.tagsChanged = false;
            }
            const prodId = response.data.id;
            // add picture after product info
            if (vm.product_image && vm.product_image.name) {
              var api_url = process.env.VUE_APP_API_URL + '/api/stores/' + vm.$store.getters.activeStore.id + '/products/' + response.data.id + "/";
              vm.sendPicture(api_url)
                  .then(function (result) {
                    // add product to VUEX with new picture included
                    vm.$store.dispatch('admin/addProduct', result.data);
                    vm.$store.dispatch('admin/fetchManagementOptions');

                    if (vm.printFileChanged) {
                      vm.sendPrintFile(api_url).then(function () {
                        // move to edit product after creation
                        vm.$router.push({
                          name: 'storemanagement',
                          params: {tab: 'products', action: 'edit', itemId: prodId}
                        });
                      });
                    } else {
                      vm.$toastr.s("New product has been created.");
                      vm.updating = false;
                      // move to edit product after creation
                      vm.$router.push({
                        name: 'storemanagement',
                        params: {tab: 'products', action: 'edit', itemId: prodId}
                      });
                    }
                  })
                  .catch(function () {
                    vm.$toastr.w("New product was successfully created, but picture upload failed.");
                    // move to edit product after creation, even if picture upload fails
                    vm.$router.push({
                      name: 'storemanagement',
                      params: {tab: 'products', action: 'edit', itemId: prodId}
                    });
                    vm.updating = false;
                  });
            } else {
              // add product to VUEX if no new picture
              vm.$store.dispatch('admin/addProduct', response.data);
              vm.$store.dispatch('admin/fetchManagementOptions');
              if (vm.printFileChanged) {
                vm.sendPrintFile().then(function () {
                  // move to edit product after creation
                  vm.$router.push({name: 'storemanagement', params: {tab: 'products', action: 'edit', itemId: prodId}});
                });
              } else {
                vm.$toastr.s("New product has been created.");
                vm.updating = false;
                // move to edit product after creation
                vm.$router.push({name: 'storemanagement', params: {tab: 'products', action: 'edit', itemId: prodId}});
              }
            }
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
            document.body.style.cursor = 'default';
            vm.updating = false;
          });
    },
    updateProduct(archiveConfirm = false, notificationConfirmed = false, sendNotification = false) {
      this.updating = true;
      this.$v.$touch()
      if (this.$v.$invalid) {
        this.updating = false;
        return;
      }
      if (this.product.status === 2 && !notificationConfirmed && this.productData.status !== 2) {
        // Show notification modal when changing status for existing product
        this.showNotifyUsersModal = true;
        return;
      } else if (this.product.status === 3 && !archiveConfirm) {
        // Show modal if trying to archive without confirm
        this.updating = false;
        this.showArchiveProductModal = true;
        return;
      } else if (this.product.status === 3 && archiveConfirm) {
        // close modal and update
        this.showArchiveProductModal = false;
        //remove tags from product when archiving
        this.product.tags = [];
        this.tagsChanged = true;
      }
      const vm = this;
      let data = {...this.product};
      data['notify_users'] = sendNotification;
      data['length'] = data['length'] === ''? null : data['length']
      data['width'] = data['width'] === ''? null : data['width']
      data['height'] = data['height'] === ''? null : data['height']
      data['weight'] = data['weight'] === ''? null : data['weight']
      // update picture separately
      delete data.primary_image;
      delete data.primary_thumb_big;
      delete data.primary_thumb_small;
      // update print_file separately
      delete data.print_file;

      // update variant print files separately
      for (let v of data.variants) {
        delete v.print_file;
      }
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/products/' + this.product.id + "/";
      let method = 'PUT';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(function (response) {
            if (vm.producerChanged || vm.warehouseChanged) {
              // refresh suppliers in global state
              vm.$store.dispatch('admin/clearAndFetchSuppliers')
            }

            vm.variantNameChanged = false;
            const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + vm.$store.getters.activeStore.id + '/products/' + response.data.id + "/"
            if (vm.tagsChanged) {
              vm.$store.dispatch('updateProductToTags', {productId: data.id, tagIds: data.tags, isLeaseProduct: false})
            }
            // update picture after product info
            if (vm.product_image && vm.product_image.name) {
              vm.sendPicture(api_url).then(function (result) {
                // update product to VUEX with new picture included
                vm.$store.dispatch('admin/updateProduct', result.data)
                // update also catalog
                vm.$store.dispatch('updateProduct', result.data)
                vm.product = {...result.data};
                if (vm.printFileChanged) {
                  vm.sendPrintFile();
                } else {
                  vm.updating = false;
                  vm.$toastr.s("Your changes have been saved.");
                }
              });
            } else {
              vm.sendGalleryImages(api_url).then(function () {
                // update product to VUEX if no new picture
                vm.$store.dispatch('admin/updateProduct', response.data)
                // update also catalog
                vm.$store.dispatch('updateProduct', response.data)
                vm.product = {...response.data};
                document.body.style.cursor = 'default';
                if (vm.printFileChanged) {
                  vm.sendPrintFile();
                } else {
                  vm.updating = false;
                  vm.$toastr.s("Your changes have been saved.");
                }
              });
            }
            if (archiveConfirm) {
              // redirect to productlist if archived
              vm.$router.push({name: 'storemanagement', params: {tab: 'products'}});
            }
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
            document.body.style.cursor = 'default';
            vm.updating = false;
          });
    },
    sendPicture(api_url) {
      let productFormData = new FormData();
      const vm = this;
      const store = this.$store.getters.activeStore;
      productFormData.append('primary_image', this.product_image);
      productFormData.append('store', store.id);
      let headers = {};
      headers['Content-Type'] = 'multipart/form-data';
      return this.$http({
        method: 'PATCH',
        url: api_url,
        data: productFormData,
        headers: headers
      })
          .then(function (response) {
            return vm.sendGalleryImages(api_url).then(function () {
              return response;
            })
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(error.request.responseText)
            }
            vm.$toastr.e(error);
            document.body.style.cursor = 'default';
          });
    },
    sendGalleryImages(api_url) {
      let url = api_url + "gallery/";
      let productFormData = new FormData();
      const vm = this;
      for (const [index, i] of this.product_images.entries()) {
        productFormData.append('image' + index + '_file', i.file);
        productFormData.append('image' + index + '_id', i.id);
        productFormData.append('image' + index + '_order_nbr', index);
      }
      let headers = {
        'Content-Type': 'multipart/form-data'
      }
      return this.$http({
        method: 'POST',
        url: url,
        data: productFormData,
        headers: headers
      })
          .then(function (response) {
            vm.product_images = response.data;
            return response;
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(error.request.responseText)
            }
            vm.$toastr.e(error);
            document.body.style.cursor = 'default';
          });
    },
    addVariant(allowSingle = false) {
      let count = 1;
      if (this.product.variants.length < 2 && !allowSingle) {
        count = 2 - this.product.variants.length;
      }
      for (let i = 0; i < count; i++) {
        const variant = {
          // Set product name as default when only one item in stock management
          name: allowSingle ? this.product.name : "",
          stock_quantity: 0,
          low_stock_threshold: 0,
          sku: null,
          barcode: null,
        }
        if (this.product.id) {
          variant.product = this.product.id;
        }
        this.product.variants.push(variant);
      }
    },
    removeVariant(removeIndex = this.removeVariantIndex) {
      this.updating = true;
      const vm = this;
      let variants = [this.product.variants[removeIndex]];
      let removeAll = false;
      const store_id = this.$store.getters.activeStore.id;

      if (this.product.variants.length <= 2) {
        if (!this.stockManagement) {
          // Remove both variants if only two created and stock management disabled.
          variants = this.product.variants;
          removeAll = true;
        }
      }
      let renameLast = false;
      if (variants.length === 1 && this.stockManagement && this.product.variants.length === 2) {
        // Leaving only one variant, rename to product name
        renameLast = true;
      }

      for (const variant of variants) {
        if (variant.id) {
          let variantName = variant.name;
          this.$http({
            method: 'delete',
            url: process.env.VUE_APP_API_URL + '/api/stores/' + store_id + '/products/variants/' + variant.id + '/',
          })
              .then(function () {
                vm.product.variants = removeAll ? [] : vm.product.variants.filter(v => v.id !== variant.id);
                vm.$store.dispatch('removeVariant', {
                  removeAll: removeAll,
                  variantId: variant.id,
                  productId: vm.product.id
                });
                vm.removeVariantIndex = -1;
                vm.$toastr.s(
                    "Variant " + variantName + " deleted."
                );
                if (renameLast) {
                  vm.renameLastVariant();
                }
                // Variant deleted from backend, refresh product to get stock updates
                const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + vm.$store.getters.activeStore.id + '/products/' + vm.product.id + "/"
                vm.$http({
                  method: 'get',
                  url: api_url,
                })
                    .then(function (response) {
                      vm.product = {...response.data};
                      // update product to VUEX
                      vm.$store.dispatch('updateProduct', response.data)
                      vm.updating = false;
                    })
              })
              .catch(function (error) {
                if (error.request) {
                  vm.$toastr.e(
                      error.request.responseText
                  );
                }
                vm.$toastr.e(
                    error
                );
                document.body.style.cursor = 'default';
                vm.updating = false;
              });
        } else {
          vm.product.variants = removeAll ? [] : vm.product.variants.filter((v, index) => index !== removeIndex);
          vm.removeVariantIndex = -1;
          if (renameLast) {
            vm.renameLastVariant();
          } else {
            vm.updating = false;
          }
        }
      }
    },
    renameLastVariant() {
      // There should be only one variant and stock management enabled
      if (this.stockManagement && this.product.variants.length === 1) {
        this.product.variants[0].name = this.product.name;
        if (this.product.variants[0].id) {
          const store_id = this.$store.getters.activeStore.id;
          const vm = this;
          this.$http({
            method: 'put',
            url: process.env.VUE_APP_API_URL + '/api/stores/' + store_id + '/products/variants/' + this.product.variants[0].id + '/',
            data: this.product.variants[0],
          })
              .then(function (response) {
                vm.$store.dispatch('updateVariant', response.data);
                vm.updating = false;
              })
              .catch(function (error) {
                if (error.request) {
                  vm.$toastr.e(
                      error.request.responseText
                  );
                }
                vm.$toastr.e(
                    error
                );
                vm.updating = false;
              });
        } else {
          this.$store.dispatch('updateVariant', this.product.variants[0]);
          this.updating = false;
        }
      }
    },
    handleFileUpload(primary) {
      if (primary && this.$refs.product_image.files.length == 0) {
        return;
      } else if (!primary && this.$refs.product_images.files.length == 0) {
        return;
      }
      let file = primary ? this.$refs.product_image.files[0] : this.$refs.product_images.files[0];

      if (!this.acceptedMimeTypes.includes(file.type)) {
        if (primary) {
          this.$refs.product_image.value = null;
        } else {
          this.$refs.product_images.value = null;
        }
        this.$toastr.e("Please choose gif, jpg, png or webp file.");
        return;
      } else if (file.size > 4000000) {
        if (primary) {
          this.$refs.product_image.value = null;
        } else {
          this.$refs.product_images.value = null;
        }
        this.$toastr.e("File size must be under 4MB");
        return;
      }

      if (primary) {
        this.product_image = this.$refs.product_image.files[0];
        // Show preview of the image
        let reader = new FileReader();
        reader.addEventListener("load", function () {
          this.showPreview = true;
          this.imagePreview = reader.result;
        }.bind(this), false);
        if (this.product_image) {
          if (/\.(jpe?g|png|gif|webp)$/i.test(this.product_image.name)) {
            reader.readAsDataURL(this.product_image);
          }
        }
      } else {
        let newImg = {file: this.$refs.product_images.files[0]}
        if (newImg.file.name.length > 80) {
          const shorterName = this.shortenName(newImg.file.name)
          // replace the file with new one to update the name
          newImg.file = new File([newImg.file], shorterName, {
            type: newImg.file.type,
            lastModified: newImg.file.lastModified,
          });
        }

        let reader = new FileReader();
        const vm = this;
        reader.addEventListener("load", function () {
          newImg['thumb_big'] = reader.result;
          newImg['id'] = null;
          vm.product_images.push(newImg);
        }.bind(this), false);
        if (newImg.file) {
          if (/\.(jpe?g|png|gif|webp)$/i.test(newImg.file.name)) {
            reader.readAsDataURL(newImg.file);
          }
        }
        this.$refs.product_images.value = null;
      }
    },
    clearFile() {
      this.product_image = '';
      this.$refs.product_image.value = null;
      this.showPreview = false;
      this.imagePreview = '';
    },
    removeGalleryItem(index) {
      this.product_images.splice(index, 1);
    },
    galleryThumb(index) {
      const prod = this.product_images[index];
      if (!this.product_images.length || !prod) {
        return ''
      }
      if (prod && prod.id) {
        let path = prod.thumb_big ? prod.thumb_big : prod.primary;
        if (path.startsWith(process.env.VUE_APP_IMAGE_HOST)) {
          return path;
        } else {
          return process.env.VUE_APP_IMAGE_HOST + path;
        }
      } else {
        return prod.thumb_big
      }
    },
    editStockModal(index) {
      this.editVariant = {...this.product.variants[index]}
      this.showModal = true;
    },
    hideModal() {
      this.editVariant = null;
      this.stockEditValue = 0;
      this.showModal = false;
    },
    editStock() {
      if (this.stockEditValue == 0) {
        this.showModal = false;
        return;
      }
      document.body.style.cursor = 'wait';
      this.updating = true;
      this.showModal = false;
      const vm = this;
      const store_id = this.$store.getters.activeStore.id;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + store_id + '/products/variants/' + this.editVariant.id + '/update_stock/';
      let method = 'POST';
      const data = {stock_edit: this.stockEditValue}
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(function (response) {
            vm.product.variants = vm.product.variants.map(v => v.id !== vm.editVariant.id ? v : {...v, ...response.data.variant});
            vm.$store.dispatch('updateVariant', response.data.variant);
            vm.product.stock_updates = [response.data.update, ...vm.product.stock_updates];
            vm.$store.dispatch('updateProduct', {
              id: response.data.variant.product,
              stock_updates: vm.product.stock_updates
            });
            vm.$toastr.s("Stock updated successfully.");
            vm.stockEditValue = 0;
            vm.editVariant = null;
            vm.updating = false;
            document.body.style.cursor = 'default';
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
            vm.stockEditValue = 0;
            vm.editVariant = null;
            document.body.style.cursor = 'default';
            vm.updating = false;
          });
    },
    closeArchiveProductModal() {
      this.showArchiveProductModal = false;
    },
    parseDate(date_str) {
      const date = new Date(date_str);
      const month = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
      const day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
      return day + '/' + month + '/' + date.getFullYear();
    },
    userNotification(sendNotification) {
      this.showNotifyUsersModal = false;
      if (!this.editProduct) {
        this.createProduct(true, sendNotification);
      } else {
        this.updateProduct(false, true, sendNotification);
      }
    },
    sendStockLevelUpdate() {
      this.updating = true;
      this.showStockLevelModal = false;
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/products/' + this.product.id + "/stock_notification/";
      let method = 'POST';
      const data = {notify_levels: true}
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(function () {
            vm.$toastr.s("Stock level update sent to all users.");
            vm.updating = false;
          })
          .catch(function (error) {
            if (error.response.data && error.response.data.detail) {
              vm.$toastr.e(
                  error.response.data.detail
              );
            } else {
              vm.$toastr.e(
                  error
              );
            }
            vm.updating = false;
          });
    },
    shortenName(fileName) {
      // Make sure the filename is max 80char + extension
      let newFileName = fileName.substring(0, fileName.lastIndexOf(".")).substring(0, 80);
      const fileExt = fileName.substring(fileName.lastIndexOf(".") + 1)
      if (!fileName.lastIndexOf(".")) {
        // no dot in filename, just shorten the string
        newFileName = fileName.substring(0, 80);
      } else {
        // add file extension to name
        newFileName += "." + fileExt;
      }
      return newFileName;
    },
    handlePrintFile() {
      let file = this.$refs.printFile.files[0]
      if (file.size > this.maxPrintFileSize) {
        this.$refs.printFile.value = null;
        this.$toastr.e("Print file size must be under 500MB");
        return;
      }
      if (file.name.length > 80) {
        const shorterName = this.shortenName(file.name)
        // replace the file with new one to update the name
        this.printFileObj = new File([file], shorterName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      } else {
        this.printFileObj = file;
      }
      this.printFileChanged = true;
      return;
    },
    saveVariantPrintFile(variantId) {
      this.updating = true;
      let file = this.$refs['printFileVar_' + variantId][0].files[0];
      if (file.size > this.maxPrintFileSize) {
        this.$refs['printFileVar_' + variantId][0].value = null;
        this.$toastr.e("Variant print file size must be under 500MB");
        this.updating = false;
        return;
      }
      let printFileObj = null;
      if (file.name.length > 80) {
        const shorterName = this.shortenName(file.name)
        // replace the file with new one to update the name
        printFileObj = new File([file], shorterName, {
          type: file.type,
          lastModified: file.lastModified,
        });
      } else {
        printFileObj = file;
      }
      this.uploadingVariantId = variantId;
      const vm = this;
      this.$store.dispatch('admin/getPreSignedPostUrl', {
        uploadType: 'variantPrintFile',
        fileName: printFileObj.name,
        productId: this.product.id,
        variantId: variantId
      })
          .then(function (response) {
            vm.$store.dispatch('admin/uploadToPresignedPostUrl', {
              presignedPostData: response.data,
              fileObj: printFileObj
            })
                .then(function () {
                  // update the filename to variant
                  const fileUrl = response.data.url + response.data.fields.key;
                  vm.product.variants = [...vm.product.variants.map(v => v.id !== variantId ? v : {...v, ...{print_file: fileUrl}})];
                  vm.updating = false;
                  vm.uploadingVariantId = null;
                  vm.$toastr.s("Your changes have been saved.");
                })
          })
      // TODO: Fix this to use print file also locally without S3
      // let productFormData = new FormData();
      // const vm = this;
      // const store = this.$store.getters.activeStore;
      // const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + store.id + '/products/variants/' + variantId + '/'
      // productFormData.append('print_file', printFileObj);
      // productFormData.append('store', store.id);
      // let headers = {};
      // headers['Content-Type'] = 'multipart/form-data';
      // this.$http({
      //   method: 'PATCH',
      //   url: api_url,
      //   data: productFormData,
      //   headers: headers
      // })
      // .then(function (response) {
      //   vm.$store.dispatch('updateVariant', response.data);
      //   vm.product.variants = vm.product.variants.map(variant => variant.id !== response.data.id ? variant : {...variant, ...response.data})
      //   vm.$toastr.s("A print file has been added successfully.");
      //   vm.updating = false;
      // })
      // .catch(function (error) {
      //   if (error.request){
      //     vm.$toastr.e(error.request.responseText)
      //   }
      //   vm.$toastr.e(error);
      //   vm.updating = false;
      // });
    },
    removeVariantPrintFile(variantId) {
      const vm = this;
      this.updating = true;
      const data = {'print_file': null}
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/products/variants/' + variantId + "/";
      let method = 'PATCH';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(function (response) {
            vm.$store.dispatch('updateVariant', response.data);
            vm.product.variants = vm.product.variants.map(variant => variant.id !== response.data.id ? variant : {...variant, ...response.data})
            vm.$toastr.s("Print file removed successfully.");
            vm.updating = false;
          })
          .catch(function (error) {
            if (error.response && error.response.data && error.response.data.message) {
              vm.$toastr.e(
                  error.response.data.message
              );
            }
            vm.$toastr.e(
                error
            );
            vm.updating = false;
          });
    },
    getPrintFileName(path) {
      if (path) {
        return path.split('/').slice(-1)[0];
      }
      return null;
    },
    removePrintFile() {
      const vm = this;
      if (!this.productData.print_file) {
        this.$refs.printFile.value = '';
        this.printFileObj = null;
        vm.updating = true;
        this.printFileChanged = false;
        setTimeout(() => {
          vm.updating = false;
        }, 500);
        return;
      }
      this.updating = true;
      const data = {'print_file': null}
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/' + this.$store.getters.activeStore.id + '/products/' + this.product.id + "/";
      let method = 'PATCH';
      this.$http({
        method: method,
        url: api_url,
        data: data,
      })
          .then(function (response) {
            vm.$store.dispatch('updateProduct', response.data)
            vm.$toastr.s("Print file removed successfully.");
            vm.product = {...response.data};
            vm.printFileObj = null;
            vm.updating = false;
            setTimeout(() => {
              // make sure ref is visible before clearing
              vm.$refs.printFile.value = '';
            }, 500);
          })
          .catch(function (error) {
            if (error.response && error.response.data && error.response.data.message) {
              vm.$toastr.e(
                  error.response.data.message
              );
            }
            vm.$toastr.e(
                error
            );
            vm.updating = false;
          });
    },
    sendPrintFile() {
      const vm = this;
      if (this.printFileObj.size > this.maxPrintFileSize) {
        this.$toastr.e("Print file size must be under 500MB");
        this.printFileObj = null;
        this.updating = false;
        return;
      }
      this.uploadingVariantId = null;
      this.$store.dispatch('admin/getPreSignedPostUrl', {
        uploadType: 'printFile',
        fileName: this.printFileObj.name,
        productId: this.product.id
      })
          .then(function (response) {
            vm.$store.dispatch('admin/uploadToPresignedPostUrl', {
              presignedPostData: response.data,
              fileObj: vm.printFileObj
            })
                .then(function () {
                  vm.updating = false;
                  vm.$toastr.s("Your changes have been saved.");
                })
          })
      // TODO: Fix this to use print file also locally without S3
      // let productFormData = new FormData();
      // const vm = this;
      // const store = this.$store.getters.activeStore;
      // productFormData.append('print_file', this.printFileObj);
      // productFormData.append('store', store.id);
      // let headers = {};
      // headers['Content-Type'] = 'multipart/form-data';
      // return this.$http({
      //   method: 'PATCH',
      //   url: api_url,
      //   data: productFormData,
      //   headers: headers
      // })
      // .then(function (response) {
      //   return response;
      // })
      // .catch(function (error) {
      //   if (error.request){
      //     vm.$toastr.e(error.request.responseText)
      //   }
      //   vm.$toastr.e(error);
      //   document.body.style.cursor='default';
      //   vm.updating = false;
      // });
    },
    cancelPrintFileUpload() {
      this.$store.dispatch('admin/cancelPrintFileUpload');
      if (this.uploadingVariantId) {
        // removevariant printfile
        this.removeVariantPrintFile(this.uploadingVariantId);
      } else {
        // remove product print file
        this.removePrintFile();
      }
    },
    openHSCodesModal() {
      this.updateHSCodes()
      this.showHSCodesModal = true;
    },
    hideHSCodesModal() {
      this.showHSCodesModal = false;
    },
    clearHSCode() {
      this.selectedHSCode = []
      this.product.hs_code = null
      this.showHSCodesModal = false;
    },
    openHSCategory(id) {
      if(this.selectedHSCode.length > 0) {
        const firstItem = this.selectedHSCode[0]
      if(firstItem.length === id.length && firstItem !== id) {
        this.$set(this.hsCodes[firstItem], 'show', 0);
        this.selectedHSCode = []
      }
      }
      if(this.hsCodes[id].show === 0){
      const vm = this;
      const api_url = process.env.VUE_APP_API_URL + '/api/stores/hs-codes/' + id;
      let method = 'GET';
      this.$http({
        method: method,
        url: api_url,
      })
          .then(function (response) {
            vm.updateHSCategory(id,response.data)
          })
          .catch(function (error) {
            if (error.request) {
              vm.$toastr.e(
                  error.request.responseText
              );
            }
            vm.$toastr.e(
                error
            );
          });
      }else{
        this.$set(this.hsCodes[id], 'show', 0);
      }
    },
    updateHSCodes() {
      const transformedObject = {};
      this.hsCodesCategories.forEach(([key, value]) => {
        transformedObject[key] = {"name": value, "show": 0};
      });
      this.hsCodes = transformedObject
    },
    parseObject(obj) {
      const result = {};
      result['show'] = 0
      const categories = []
      for (const key in obj) {
        if(key !== 'label' && key !== 'category') {
          const subResult = {}
          subResult['show'] = 0
          const subCategories = []
          for (const subKey in obj[key]) {
            if(subKey !== 'label') {
              let innerObj = obj[key][subKey]
                const innerSubResult = {}
                innerSubResult['show'] = 0
                const innerSubCategories = []
                for (const innerSubKey in innerObj) {
                  if(innerSubKey !== 'label') {
                    innerObj[innerSubKey]['category'] = innerSubKey
                    innerObj[innerSubKey]['show'] = 0
                    innerSubCategories.push(innerObj[innerSubKey])
                  }else{
                    innerSubResult['label'] = innerObj[innerSubKey]
                    innerSubResult['category'] = subKey
                    innerSubResult['show'] = 0
                  }
                }
              innerSubResult['categories'] = innerSubCategories
              subCategories.push(innerSubResult)
            }else{
              subResult['label'] = obj[key][subKey]
              subResult['category'] = key
              subResult['show'] = 0
            }
          }
          subResult['categories'] = subCategories
          categories.push(subResult)
        }else{
          result[key] = obj[key]
        }
      }
      result["categories"] = categories
      return result;
    },
    updateShowValue(obj, categoryToUpdate) {
    if (obj.category === categoryToUpdate) {
        obj.show = 1;
        return;
    }

    if (obj.categories) {
        for (const category of obj.categories) {
            this.updateShowValue(category, categoryToUpdate);
        }
    }
    },
    updateShowValueToZero(obj, categoryToUpdate) {
    if (obj.category === categoryToUpdate) {
        obj.show = 0;
        return;
    }

    if (obj.categories) {
        for (const category of obj.categories) {
            this.updateShowValueToZero(category, categoryToUpdate);
        }
    }
    },
    openHSNode(id) {
        this.updateShowValue(this.hsCodes[this.selectedHSCode[0]], id);
        this.selectedHSCode.push(id)
    },
    closeHSNode(id) {
        this.updateShowValueToZero(this.hsCodes[this.selectedHSCode[0]], id);
        this.selectedHSCode.pop()
    },
    updateHSNode(id) {
      const lastItem = this.selectedHSCode[this.selectedHSCode.length - 1];
      if(id.length === lastItem.length && id !== lastItem){
        this.closeHSNode(lastItem)
      }
      if(id === lastItem) {
        this.closeHSNode(id)
      }else{
        this.openHSNode(id)
      }
    },
    updateHSCategory(id,data) {
      if (!this.hsCodes[id]) {
      this.$set(this.hsCodes, id, {});
      }
      let categoryData = []
      data.forEach(item => {
        let result = {};
        result = this.parseObject(item)
      categoryData.push(result)
      })
      this.$set(this.hsCodes[id], 'categories', categoryData);
      this.$set(this.hsCodes[id], 'show', 1);
      this.selectedHSCode.push(id)
    },
    openHSSubCategory(id, selectable) {
      event.stopPropagation();
      if(selectable){
        this.product.hs_code = id
        this.selectedHSCode = []
        this.hideHSCodesModal()
      }else{
        this.updateHSNode(id)
      }

    },
  }
}

</script>
<style scoped>
/* Your modal CSS styles here */
.modal {
  display: block;
  /* Positioning and other styles */
}
.modal-content {
  /* Modal content styles */
  background-color: #fff;
  padding: 20px;
  border: 1px solid #ccc;
}
.custom-list {
  list-style: none;
  cursor: pointer;
}
.custom-list > li {
  cursor: pointer;
}
</style>